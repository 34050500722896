import React, { useEffect, useState } from "react";
// ui ORM
import CardElectricalXrayUltrasoundUX from "./CardElectricalXrayUltrasoundUX";
import PreOPButtonAction from "./PreOPButtonAction";
import { useIntl } from "react-intl";

const CARD_KEY: string = "electricalData";
const FORM_CODE: string = "CardElectricalXray";
const FORM_NAME: string = "Electrical & X-ray & Ultrasound";
const FORM_VERSION: string = "1.0";

type CardElectricalXrayUltrasoundProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  buttonLoadCheck?: any;

  // select OR
  selectedOrOrder?: any;

  // options
  masterOptions?: any;

  // seq
  runSequence?: any;
  PerioperativeNursingSequence?: any;
};

const CardElectricalXrayUltrasoundInitial: CardElectricalXrayUltrasoundProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,

  // CommonInterface
  buttonLoadCheck: null,

  // select OR
  selectedOrOrder: {},

  // options
  masterOptions: {},

  // seq
  runSequence: null,
  PerioperativeNursingSequence: {},
};

const Tourniquet = {
  ronud: 0,
  tourniquetSide: "",
  pressure: "",
  timeOn: "",
  timeOff: "",
};

const Defibrillator = {
  external: false,
  internal: false,
  timeOn: "",
  power: "",
};

type OptionType = {
  key: string;
  text: string;
  value: string;
};

type OptionsState = {
  orGroundPadSiteOption: OptionType[];
};

const CardElectricalXrayUltrasound: React.FC<CardElectricalXrayUltrasoundProps> = (props: any) => {
  const [options, setOptions] = useState<OptionsState>({
    orGroundPadSiteOption: [],
  });

  useEffect(() => {
    props.runSequence({
      sequence: "PerioperativeNursing",
    });
  }, []);

  const updateOptions = (data: any, masterKey: string, optionsKey: keyof OptionsState) => {
    const masterData = props.masterOptions?.[masterKey] || [];

    // แก้ไขข้อมูลให้อยู่ในรูปแบบ array
    let valueInOption = Array.isArray(data) ? data : [data];

    // ค้นหา option ที่เพิ่มขึ้นมาเอง
    const optionData = (valueInOption || [])
      .filter(
        (item: any) =>
          (item && typeof item === "string") ||
          (item?.medicine_name && typeof item?.medicine_name === "string")
      )
      .map((item) => ({
        key: item,
        text: item,
        value: item,
      }));

    // รวม option จาก masterData และ options (option ที่เพิ่มขึ้นเอง)
    const combinedOption = [...new Set([...masterData, ...(options[optionsKey] || [])])];

    // ตรวจสอบหาเฉพาะ optionData ที่ไม่ซ้ำใน combinedOption
    const newOptions = optionData.filter(
      (item: any) => !combinedOption.some((option) => option.value === item.value)
    );

    setOptions((prevOptions) => ({
      ...prevOptions,
      [optionsKey]: newOptions.length ? [...combinedOption, ...newOptions] : combinedOption,
    }));
  };

  useEffect(() => {
    updateOptions(
      props.PerioperativeNursingSequence?.electricalData?.data?.ground_pad_site,
      "orGroundPadSite",
      "orGroundPadSiteOption"
    );
  }, [
    props.masterOptions?.orGroundPadSite,
    props.PerioperativeNursingSequence?.electricalData?.data?.ground_pad_site,
  ]);

  const handleSave = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "SAVE",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleConfirm = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "CONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleUnconfirm = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "UNCONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleChangeData = (_event: any, data: any) => {
    let value = "";
    if (data.type === "checkbox") {
      value = data.checked;
    } else {
      value = data.value;
    }
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "SET_DATA",
      cardKey: CARD_KEY,
      key: data.name,
      value: value,
    });
  };

  const handleAddOptions = (e: any, v: any) => {
    let newOption = { text: v.value, value: v.value, key: v.value };

    const optionMap: { [key: string]: keyof OptionsState } = {
      orGroundPadSite: "orGroundPadSiteOption",
    };

    const key = optionMap[v.name];
    if (key) {
      setOptions({ ...options, [key]: [...options[key], newOption] });
    }
  };

  return (
    <>
      <CardElectricalXrayUltrasoundUX
        options={options}
        masterOptions={props.masterOptions}
        handleChangeData={handleChangeData}
        handleAddOptions={handleAddOptions}
        PerioperativeNursingSequence={props.PerioperativeNursingSequence}
        frequency={props.PerioperativeNursingSequence?.electricalData?.data?.frequency}
        groundPadSite={props.PerioperativeNursingSequence?.electricalData?.data?.ground_pad_site}
        isSurgicalCautery={
          props.PerioperativeNursingSequence?.electricalData?.data?.is_surgical_cautery
        }
        isUs={props.PerioperativeNursingSequence?.electricalData?.data?.is_us}
        isXray={props.PerioperativeNursingSequence?.electricalData?.data?.is_xray}
        isGroundPadSite={
          props.PerioperativeNursingSequence?.electricalData?.data?.is_ground_pad_site
        }
        surgicalCautery={props.PerioperativeNursingSequence?.electricalData?.data?.surgical_cautery}
        xay={props.PerioperativeNursingSequence?.electricalData?.data?.xray}
        status={props.PerioperativeNursingSequence?.electricalData?.status}
        buttonSave={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="save"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave}
          />
        }
        buttonConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="confirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleConfirm}
          />
        }
        buttonUnconfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="unconfirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleUnconfirm}
          />
        }
        languageUX={props.languageUX}
      />
    </>
  );
};

CardElectricalXrayUltrasound.defaultProps = CardElectricalXrayUltrasoundInitial;

export default React.memo(CardElectricalXrayUltrasound);
