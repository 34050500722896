import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Button, Dropdown, FormField, FormGroup, Input, Modal } from "semantic-ui-react";
// ui common
import EmployeeToken from "react-lib/apps/common/EmployeeToken";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import TimeComboBox from "react-lib/apps/common/TimeComboBox";
// ui LAB
import CardHistoryCentralLab from "../LAB/CardHistoryCentralLab";
// ui ORM
import CardHoldingRoomUX from "./CardHoldingRoomUX";
import PreOPButtonAction from "./PreOPButtonAction";
import { useIntl } from "react-intl";

const CARD_KEY: string = "holdingRoomData";
const FORM_CODE: string = "CardHoldingRoom";
const FORM_NAME: string = "Holding Room";
const FORM_VERSION: string = "0.1";

type CardHoldingRoomProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // select OR
  selectedOrOrder?: any;

  // options
  masterOptions?: any;

  // seq
  runSequence?: any;
  PreOperationSequence?: any;
  HistoryCentralLabSequence?: any;
};

const CardHoldingRoomInitial: CardHoldingRoomProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,

  // CommonInterface
  errorMessage: null,
  successMessage: null,
  buttonLoadCheck: null,

  // select OR
  selectedOrOrder: {},

  // options
  masterOptions: {},

  // seq
  runSequence: null,
  PreOperationSequence: {},
  HistoryCentralLabSequence: {},
};

type OptionType = {
  key: string;
  text: string;
  value: string;
};

type OptionsState = {
  limitationOptions: OptionType[];
  planAndImpleHrOptions: OptionType[];
  orMedicationOptions: OptionType[];
  orNDOptions: OptionType[];
  orGoal: OptionType[];
  orEvaluation: OptionType[];
};

const CardHoldingRoom: React.FC<CardHoldingRoomProps> = (props: any) => {
  const [openModHistoryCentralLab, setOpenModHistoryCentralLab] = useState<boolean>(false);
  const [options, setOptions] = useState<OptionsState>({
    limitationOptions: [],
    planAndImpleHrOptions: [],
    orMedicationOptions: [],
    orNDOptions: [],
    orGoal: [],
    orEvaluation: [],
  });

  // Callback Effect
  const handleCloseErrMsg = useCallback(() => {
    props.setProp(`errorMessage.${CARD_KEY}`, null);
    props.setProp(`successMessage.${CARD_KEY}`, null);
  }, []);

  useEffect(() => {
    props.runSequence({
      sequence: "PreOperation",
      action: "FETCH_FORM_DATA_LATEST",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formVersion: FORM_VERSION,
    });

    return () => {
      handleCloseErrMsg();
    };
  }, []);

  const updateOptions = (
    holdingRoomData: any,
    masterKey: string,
    optionsKey: keyof OptionsState,
    mapHoldingRoomData: (item: any) => OptionType
  ) => {
    const masterData = props.masterOptions?.[masterKey] || [];

    // แก้ไขข้อมูลให้อยู่ในรูปแบบ array
    let valueInOption = Array.isArray(holdingRoomData) ? holdingRoomData : [holdingRoomData];

    // ค้นหา option ที่เพิ่มขึ้นมาเอง
    const optionData = (valueInOption || [])
      .filter(
        (item: any) =>
          (item && typeof item === "string") ||
          (item?.medicine_name && typeof item?.medicine_name === "string")
      )
      .map(mapHoldingRoomData);

    // รวม option จาก masterData และ options (option ที่เพิ่มขึ้นเอง)
    const combinedOption = [...new Set([...masterData, ...(options[optionsKey] || [])])];

    // ตรวจสอบหาเฉพาะ optionData ที่ไม่ซ้ำใน combinedOption
    const newOptions = optionData.filter(
      (item: any) => !combinedOption.some((option) => option.value === item.value)
    );

    setOptions((prevOptions) => ({
      ...prevOptions,
      [optionsKey]: newOptions.length ? [...combinedOption, ...newOptions] : combinedOption,
    }));
  };

  // add limitation
  useEffect(() => {
    updateOptions(
      props.PreOperationSequence?.holdingRoomData?.data?.limitation,
      "limitation",
      "limitationOptions",
      (item) => ({
        key: item,
        text: item,
        value: item,
      })
    );
  }, [
    props.masterOptions?.limitation,
    props.PreOperationSequence?.holdingRoomData?.data?.limitation,
  ]);

  // orMedication
  useEffect(() => {
    updateOptions(
      props.PreOperationSequence?.holdingRoomData?.data?.medication_item,
      "orMedication",
      "orMedicationOptions",
      (item) => ({
        key: item.medicine_name,
        text: item.medicine_name,
        value: item.medicine_name,
      })
    );
  }, [
    props.masterOptions?.orMedication,
    props.PreOperationSequence?.holdingRoomData?.data?.medication_item,
  ]);

  useEffect(() => {
    const nursingDiagnosisData =
      props.PreOperationSequence?.holdingRoomData?.data?.nursing_diagnosis_item;

    const allNursingDiagnosis = [
      ...new Set((nursingDiagnosisData || []).flatMap((item: any) => item?.nursing_diagnosis)),
    ];
    updateOptions(allNursingDiagnosis, "orNursingDiagnosis", "orNDOptions", (item) => ({
      key: item,
      text: item,
      value: item,
    }));

    const allGoal = [...new Set((nursingDiagnosisData || []).flatMap((item: any) => item?.goal))];
    updateOptions(allGoal, "orGoal", "orGoal", (item) => ({
      key: item,
      text: item,
      value: item,
    }));

    const allPlanAndImpleHr = [
      ...new Set(
        (nursingDiagnosisData || []).flatMap((item: any) => item?.plan_and_implementation)
      ),
    ];
    updateOptions(allPlanAndImpleHr, "planAndImpleHr", "planAndImpleHrOptions", (item) => ({
      key: item,
      text: item,
      value: item,
    }));

    const allEvaluations = [
      ...new Set((nursingDiagnosisData || []).flatMap((item: any) => item?.evaluation)),
    ];
    updateOptions(allEvaluations, "orEvaluation", "orEvaluation", (item) => ({
      key: item,
      text: item,
      value: item,
    }));
  }, [
    props.masterOptions?.orNursingDiagnosis,
    props.masterOptions?.orGoal,
    props.masterOptions?.planAndImpleHr,
    props.masterOptions?.orEvaluation,
    props.PreOperationSequence?.holdingRoomData?.data?.nursing_diagnosis_item,
  ]);

  const handleShowLabData = () => {
    setOpenModHistoryCentralLab(true);
  };

  const handleChangeData = (_event: any, data: any) => {
    let value = "";
    if (data.type === "checkbox") {
      value = data.checked;
    } else {
      value = data.value;
    }

    props.runSequence({
      sequence: "PreOperation",
      action: "SET_DATA",
      cardKey: CARD_KEY,
      key: data.name,
      value: value,
    });
  };

  const handleAddArrayData = (key: string) => {
    let result = [...props.PreOperationSequence?.holdingRoomData?.data?.[key]];
    let objectData =
      key === "nursing_diagnosis_item"
        ? {
            nursing_diagnosis: "",
            goal: [],
            plan_and_implementation: [],
            evaluation: [],
          }
        : {
            medicine_name: "",
            route: "",
            time: "",
            code: "",
          };

    result.push(objectData);

    props.runSequence({
      sequence: "PreOperation",
      action: "SET_DATA",
      cardKey: CARD_KEY,
      key: key,
      value: result,
    });
  };

  const handleRemoveArrayData = (key: string, index: number) => {
    let result = [...props.PreOperationSequence?.holdingRoomData?.data?.[key]];
    result.splice(index, 1);

    if (result.length === 0) {
      result.push({
        medicine_name: "",
        route: "",
        time: "",
        code: "",
        codeText: "",
      });
    }

    props.runSequence({
      sequence: "PreOperation",
      action: "SET_DATA",
      cardKey: CARD_KEY,
      key: key,
      value: result,
    });
  };

  const handleChangeArrayData = (key: string, index: number, data: any) => {
    let result = [...props.PreOperationSequence?.holdingRoomData?.data?.[key]];
    result[index][data.name] = data.value;

    props.runSequence({
      sequence: "PreOperation",
      action: "SET_DATA",
      cardKey: CARD_KEY,
      key: key,
      value: result,
    });
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "PreOperation",
      action: "SAVE",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleConfirm = () => {
    props.runSequence({
      sequence: "PreOperation",
      action: "CONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleUnconfirm = () => {
    props.runSequence({
      sequence: "PreOperation",
      action: "UNCONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleAddOptions = (e: any, v: any) => {
    let newOption = { text: v.value, value: v.value, key: v.value };

    const optionMap: { [key: string]: keyof OptionsState } = {
      limitation: "limitationOptions",
      plan_and_implementation: "planAndImpleHrOptions",
      medicine_name: "orMedicationOptions",
      nursing_diagnosis: "orNDOptions",
      goal: "orGoal",
      evaluation: "orEvaluation",
    };

    const key = optionMap[v.name];
    if (key) {
      setOptions({ ...options, [key]: [...options[key], newOption] });
    }
  };

  return (
    <>
      {(props.errorMessage?.[CARD_KEY] || props.successMessage?.[CARD_KEY]) && (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={handleCloseErrMsg}
          error={props.errorMessage?.[CARD_KEY]}
          success={props.successMessage?.[CARD_KEY]}
          languageUX={props.languageUX}
        />
      )}

      <CardHoldingRoomUX
        // function
        onChangeData={handleChangeData}
        onShowLabData={handleShowLabData}
        onAddOption={handleAddOptions}
        limitationOptions={options.limitationOptions}
        planAndImpleHrOptions={options.planAndImpleHrOptions}
        orNDOptions={options.orNDOptions}
        orGoal={options.orGoal}
        orEvaluation={options.orEvaluation}
        // options
        masterOptions={props.masterOptions}
        // seq
        PreOperationSequence={props.PreOperationSequence}
        // component
        divMedication={
          <>
            {props.PreOperationSequence?.holdingRoomData?.data?.medication_item?.map(
              (item: any, index: number) => {
                return (
                  <FormGroup key={index} inline={true}>
                    <FormField inline={true} width={3}>
                      <Dropdown
                        allowAdditions={true}
                        value={item?.medicine_name}
                        name="medicine_name"
                        options={options.orMedicationOptions || []}
                        search={true}
                        selection={true}
                        fluid={true}
                        style={{ width: "100%" }}
                        onAddItem={handleAddOptions}
                        onChange={(_event: any, data: any) =>
                          handleChangeArrayData("medication_item", index, data)
                        }
                      ></Dropdown>
                    </FormField>
                    <FormField inline={true} width={4}>
                      <label>Route</label>
                      <Dropdown
                        value={item?.route}
                        name="route"
                        options={props.masterOptions?.route || []}
                        selection={true}
                        fluid={true}
                        style={{ width: "100%" }}
                        onChange={(_event: any, data: any) =>
                          handleChangeArrayData("medication_item", index, data)
                        }
                      ></Dropdown>
                    </FormField>
                    <FormField inline={true} width={4}>
                      <label>Time</label>
                      <TimeComboBox
                        defaultValue={item?.time || ""}
                        style={{ width: "100%" }}
                        onTextChange={(time: string) =>
                          handleChangeArrayData("medication_item", index, {
                            name: "time",
                            value: time,
                          })
                        }
                      />
                    </FormField>
                    <FormField inline={true} width={5}>
                      <label style={{ width: "100%", maxWidth: "max-content" }}>Given by</label>
                      <EmployeeToken
                        placeholder="Code"
                        employeeName={
                          item?.code ? decodeURIComponent(atob(item?.code.split(".")[1])) : ""
                        }
                        //
                        employeeCode={item?.codeText}
                        onChangeCodeText={(codeText: any) => {
                          props.setProp(
                            `PreOperationSequence.holdingRoomData.data.medication_item.${index}.codeText`,
                            codeText.value
                          );
                        }}
                        inputStyle={{ width: "100%" }}
                        onEnterToken={(codeText: any) => {
                          props.runSequence({
                            sequence: "PreOperation",
                            action: "USER_TOKENIZE",
                            cardKey: CARD_KEY,
                            code: codeText,
                            key: "medication_item",
                            index: index,
                          });
                        }}
                        onClearToken={() =>
                          handleChangeArrayData("medication_item", index, {
                            name: "code",
                            value: "",
                          })
                        }
                      />
                    </FormField>
                    <FormField
                      inline={true}
                      style={{
                        minWidth: "max-content",
                        padding: 0,
                        marginRight: "-0.5rem",
                      }}
                    >
                      <Button
                        color="red"
                        icon="minus"
                        size="mini"
                        style={{ margin: "0 10px 0 -10px" }}
                        onClick={() => handleRemoveArrayData("medication_item", index)}
                      ></Button>
                      <Button
                        color="green"
                        icon="plus"
                        size="mini"
                        style={{ margin: "0 0 0 10px" }}
                        onClick={() => handleAddArrayData("medication_item")}
                      ></Button>
                    </FormField>
                  </FormGroup>
                );
              }
            )}
          </>
        }
        divOtherMedication={
          <>
            {props.PreOperationSequence?.holdingRoomData?.data?.medication_other_item?.map(
              (item: any, index: number) => {
                return (
                  <FormGroup key={index} inline={true}>
                    <FormField inline={true} width={3}>
                      <Input
                        name="medicine_name"
                        value={item?.medicine_name}
                        fluid={true}
                        style={{ width: "100%" }}
                        onChange={(_event: any, data: any) =>
                          handleChangeArrayData("medication_other_item", index, data)
                        }
                      ></Input>
                    </FormField>
                    <FormField inline={true} width={4}>
                      <label>Route</label>
                      <Dropdown
                        name="route"
                        value={item?.route}
                        selection={true}
                        fluid={true}
                        style={{ width: "100%" }}
                        options={props.masterOptions?.route || []}
                        onChange={(_event: any, data: any) =>
                          handleChangeArrayData("medication_other_item", index, data)
                        }
                      ></Dropdown>
                    </FormField>
                    <FormField inline={true} width={4}>
                      <label>Time</label>
                      <TimeComboBox
                        defaultValue={item?.time || ""}
                        style={{ width: "100%" }}
                        onTextChange={(time: string) =>
                          handleChangeArrayData("medication_other_item", index, {
                            name: "time",
                            value: time,
                          })
                        }
                      />
                    </FormField>
                    <FormField inline={true} width={5}>
                      <label style={{ width: "100%", maxWidth: "max-content" }}>Given by</label>
                      <EmployeeToken
                        placeholder="Code"
                        employeeName={
                          item?.code ? decodeURIComponent(atob(item?.code.split(".")[1])) : ""
                        }
                        employeeCode={item?.codeText}
                        onChangeCodeText={(codeText: any) => {
                          props.setProp(
                            `PreOperationSequence.holdingRoomData.data.medication_other_item.${index}.codeText`,
                            codeText.value
                          );
                        }}
                        inputStyle={{ width: "100%" }}
                        onEnterToken={(codeText: any) => {
                          props.runSequence({
                            sequence: "PreOperation",
                            action: "USER_TOKENIZE",
                            cardKey: CARD_KEY,
                            code: codeText,
                            key: "medication_other_item",
                            index: index,
                          });
                        }}
                        onClearToken={() =>
                          handleChangeArrayData("medication_other_item", index, {
                            name: "code",
                            value: "",
                          })
                        }
                      />
                    </FormField>
                    <FormField
                      inline={true}
                      style={{
                        minWidth: "max-content",
                        padding: 0,
                        marginRight: "-0.5rem",
                      }}
                    >
                      <Button
                        color="red"
                        icon="minus"
                        size="mini"
                        style={{ margin: "0 10px 0 -10px" }}
                        onClick={() => handleRemoveArrayData("medication_other_item", index)}
                      ></Button>
                      <Button
                        color="green"
                        icon="plus"
                        size="mini"
                        style={{ margin: "0 0 0 10px" }}
                        onClick={() => handleAddArrayData("medication_other_item")}
                      ></Button>
                    </FormField>
                  </FormGroup>
                );
              }
            )}
          </>
        }
        divNursingDiagnosisItem={
          <>
            {(props.PreOperationSequence?.holdingRoomData?.data?.nursing_diagnosis_item || [])?.map(
              (item: any, index: number) => {
                return (
                  <FormGroup
                    style={{
                      backgroundColor: "rgb(253, 248, 221)",
                      padding: "30px 10px",
                      marginTop: "2rem",
                    }}
                  >
                    <FormField width={16}>
                      <FormGroup inline={true}>
                        <FormField inline={true} width={7}>
                          <label style={{ width: "100%", maxWidth: "max-content" }}>
                            Nursing Diagnosis
                          </label>
                          <Dropdown
                            allowAdditions={true}
                            clearable={true}
                            fluid={true}
                            name="nursing_diagnosis"
                            onAddItem={handleAddOptions}
                            onChange={(_event: any, data: any) =>
                              handleChangeArrayData("nursing_diagnosis_item", index, data)
                            }
                            options={options.orNDOptions || []}
                            search={true}
                            selection={true}
                            style={{ width: "100%" }}
                            value={item?.nursing_diagnosis}
                          ></Dropdown>
                        </FormField>
                        <FormField inline={true} width={8}>
                          <label>Goal</label>
                          <Dropdown
                            allowAdditions={true}
                            clearable={true}
                            fluid={true}
                            multiple={true}
                            name="goal"
                            onAddItem={handleAddOptions}
                            onChange={(_event: any, data: any) =>
                              handleChangeArrayData("nursing_diagnosis_item", index, data)
                            }
                            options={options.orGoal || []}
                            search={true}
                            selection={true}
                            style={{ width: "100%" }}
                            value={item?.goal || []}
                          ></Dropdown>
                        </FormField>
                        <FormField
                          inline={true}
                          width={1}
                          style={{
                            minWidth: "max-content",
                            padding: 0,
                            marginRight: "-0.5rem",
                          }}
                        >
                          {index === 0 ? (
                            <Button
                              color="green"
                              icon="plus"
                              size="mini"
                              style={{ margin: "0 0 0 10px" }}
                              onClick={() => handleAddArrayData("nursing_diagnosis_item")}
                            ></Button>
                          ) : (
                            <Button
                              color="red"
                              icon="minus"
                              size="mini"
                              style={{ margin: "0 0 0 10px" }}
                              onClick={() => handleRemoveArrayData("nursing_diagnosis_item", index)}
                            ></Button>
                          )}
                        </FormField>
                      </FormGroup>
                      <FormGroup inline={true}>
                        <FormField inline={true} width={7}>
                          <label style={{ width: "100%", maxWidth: "max-content" }}>
                            Plan and implementation
                          </label>
                          <Dropdown
                            allowAdditions={true}
                            clearable={true}
                            fluid={true}
                            multiple={true}
                            name="plan_and_implementation"
                            onAddItem={handleAddOptions}
                            onChange={(_event: any, data: any) =>
                              handleChangeArrayData("nursing_diagnosis_item", index, data)
                            }
                            options={options.planAndImpleHrOptions || []}
                            search={true}
                            selection={true}
                            style={{ width: "100%" }}
                            value={item.plan_and_implementation || []}
                          ></Dropdown>
                        </FormField>
                        <FormField inline={true} width={8}>
                          <label>Evaluation</label>
                          <Dropdown
                            allowAdditions={true}
                            clearable={true}
                            fluid={true}
                            multiple={true}
                            name="evaluation"
                            onAddItem={handleAddOptions}
                            onChange={(_event: any, data: any) =>
                              handleChangeArrayData("nursing_diagnosis_item", index, data)
                            }
                            options={options.orEvaluation}
                            search={true}
                            selection={true}
                            style={{ width: "100%" }}
                            value={item?.evaluation || []}
                          ></Dropdown>
                        </FormField>
                        <FormField inline={true} width={1}></FormField>
                      </FormGroup>
                    </FormField>
                  </FormGroup>
                );
              }
            )}
          </>
        }
        buttonSave={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PreOperationSequence}
            type="save"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave}
          />
        }
        buttonConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PreOperationSequence}
            type="confirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleConfirm}
          />
        }
        buttonUnconfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PreOperationSequence}
            type="unconfirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleUnconfirm}
          />
        }
        languageUX={props.languageUX}
      />

      {openModHistoryCentralLab && (
        <Modal
          size="large"
          closeIcon
          open={openModHistoryCentralLab}
          onClose={() => setOpenModHistoryCentralLab(false)}
        >
          <CardHistoryCentralLab
            // function
            setProp={props.setProp}
            // seq
            runSequence={props.runSequence}
            HistoryCentralLabSequence={props.HistoryCentralLabSequence}
            // data
            buttonLoadCheck={props.buttonLoadCheck}
            patientId={props.selectedOrOrder?.patient_id}
            hidePrintButton={true}
            languageUX={props.languageUX}
          />
        </Modal>
      )}
    </>
  );
};

CardHoldingRoom.defaultProps = CardHoldingRoomInitial;

export default React.memo(CardHoldingRoom);
