import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Label,
  Form,
  FormGroup,
  FormField,
  Checkbox,
  Input,
  Radio,
  Dropdown,
  Button,
  TextArea
} from 'semantic-ui-react'

const CardHoldingRoomUX = (props: any) => {
    return(
      <div
        id="CardHoldingRoom"
        style={{ margin: "15px",  padding: "20px",  backgroundColor: "rgba(198, 235, 243, 0.2)" }}>
        <div
          id="CardHoldingRoomUX-div-1"
          style={{ paddingBottom: "5px" }}>
          
          <label
            id="CardHoldingRoomUX-label-2"
            style={{ fontWeight: "bold", fontSize: "18px" }}>
            Holding Room
          </label>
          <Label
            color={props.statusColor}
            id="CardHoldingRoomUX-Label-3"
            style={{ float: "right" }}>
            {props.PreOperationSequence?.holdingRoomData?.status || ""}
          </Label>
          <hr
            id="CardHoldingRoomUX-hr-4">
          </hr>
        </div>
        <Form
          id="CardHoldingRoomUX-Form-175">
          <FormGroup
            id="CardHoldingRoomUX-FormGroup-176"
            inline={true}>
            <FormField
              className="required"
              id="CardHoldingRoomUX-FormField-177"
              inline={true}>
              <label
                id="CardHoldingRoomUX-label-181"
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Patient identification
              </label>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-178"
              inline={true}>
              <Checkbox
                checked={props.PreOperationSequence?.holdingRoomData?.data?.verbally}
                id="CardHoldingRoomUX-Checkbox-182"
                label="Verbally"
                name="verbally"
                onChange={props.onChangeData}>
              </Checkbox>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-179"
              inline={true}>
              <Checkbox
                checked={props.PreOperationSequence?.holdingRoomData?.data?.name_band}
                id="CardHoldingRoomUX-Checkbox-183"
                label="Name band"
                name="name_band"
                onChange={props.onChangeData}>
              </Checkbox>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-180"
              inline={true}>
              <Checkbox
                checked={props.PreOperationSequence?.holdingRoomData?.data?.chart}
                id="CardHoldingRoomUX-Checkbox-184"
                label="Chart"
                name="chart"
                onChange={props.onChangeData}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardHoldingRoomUX-FormGroup-185"
            inline={true}>
            <FormField
              className="required"
              id="CardHoldingRoomUX-FormField-186"
              inline={true}>
              <label
                id="CardHoldingRoomUX-label-188"
                style={{minWidth: "220px", maxWidth: "220px"}}>
                History illness
              </label>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-187"
              inline={true}
              width={10}>
              <Input
                id="CardHoldingRoomUX-Input-189"
                name="history_illness"
                onChange={props.onChangeData}
                value={props.PreOperationSequence?.holdingRoomData?.data?.history_illness || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardHoldingRoomUX-FormGroup-190"
            inline={true}
            style={{alignItems: "flex-start"}}>
            <FormField
              id="CardHoldingRoomUX-FormField-191"
              inline={true}>
              <label
                id="CardHoldingRoomUX-label-193"
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Previous Operation
              </label>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-192"
              inline={true}
              width={10}>
              <label
                id="CardHoldingRoomUX-label-194"
                style={{display: "none"}}>
                {props.PreOperationSequence?.holdingRoomData?.data?.previous_operation || "-"}
              </label>
              <TextArea
                id="CardHoldingRoomUX-TextArea-336"
                name="previous_operation"
                onChange={props.onChangeData}
                value={props.PreOperationSequence?.holdingRoomData?.data?.previous_operation || ""}>
              </TextArea>
            </FormField>
          </FormGroup>
          <FormGroup
            id="title-procedure-confirm"
            inline={true}>
            <FormField
              className="required"
              id="CardHoldingRoomUX-FormField-196"
              inline={true}>
              <label
                id="CardHoldingRoomUX-label-198"
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Procedure Confirm
              </label>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-197"
              inline={true}
              style={{marginLeft: "-7rem"}}>
              <Checkbox
                checked={props.PreOperationSequence?.holdingRoomData?.data?.procedure_confirm}
                id="CardHoldingRoomUX-Checkbox-199"
                label={props.PreOperationSequence?.holdingRoomData?.data?.procedure_confirm ? "YES" : "NO"}
                name="procedure_confirm"
                onChange={props.onChangeData}
                toggle={true}>
              </Checkbox>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-200"
              inline={true}
              width={10}>
              <Input
                disabled={!props.PreOperationSequence?.holdingRoomData?.data?.procedure_confirm}
                id="CardHoldingRoomUX-Input-201"
                name="procedure_confirm_detail"
                onChange={props.onChangeData}
                value={props.PreOperationSequence?.holdingRoomData?.data?.procedure_confirm_detail || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardHoldingRoomUX-FormGroup-202"
            inline={true}>
            <FormField
              className="required"
              id="CardHoldingRoomUX-FormField-203"
              inline={true}>
              <label
                id="CardHoldingRoomUX-label-205"
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Mark site Confirm
              </label>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-204"
              inline={true}>
              <Radio
                checked={props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm === "yes"}
                id="CardHoldingRoomUX-Radio-206"
                label="Yes"
                name="mark_site_confirm"
                onChange={props.onChangeData}
                value="yes">
              </Radio>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-207"
              inline={true}>
              <Radio
                checked={props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm === "no"}
                id="CardHoldingRoomUX-Radio-209"
                label="No"
                name="mark_site_confirm"
                onChange={props.onChangeData}
                value="no">
              </Radio>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-208"
              inline={true}>
              <Radio
                checked={props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm === "n/a"}
                id="CardHoldingRoomUX-Radio-210"
                label="N/A"
                name="mark_site_confirm"
                onChange={props.onChangeData}
                value="n/a">
              </Radio>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-321"
              inline={true}>
              <Input
                id="CardHoldingRoomUX-Input-322"
                name="mark_site_confirm_detail"
                onChange={props.onChangeData}
                style={{display: props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm === "n/a" ? "" : "none" }}
                value={props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm_detail || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardHoldingRoomUX-FormGroup-211">
            <FormField
              id="CardHoldingRoomUX-FormField-212">
              <label
                id="CardHoldingRoomUX-label-215"
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Confirmation Surgical site per PT/Family
              </label>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-213">
              <Radio
                checked={props.PreOperationSequence?.holdingRoomData?.data?.confirm_surgical === "yes"}
                id="CardHoldingRoomUX-Radio-216"
                label="Yes"
                name="confirm_surgical"
                onChange={props.onChangeData}
                value="yes">
              </Radio>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-214">
              <Radio
                checked={props.PreOperationSequence?.holdingRoomData?.data?.confirm_surgical === "no"}
                id="CardHoldingRoomUX-Radio-217"
                label="No"
                name="confirm_surgical"
                onChange={props.onChangeData}
                value="no">
              </Radio>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardHoldingRoomUX-FormGroup-218">
            <FormField
              className="required"
              id="CardHoldingRoomUX-FormField-219">
              <label
                id="CardHoldingRoomUX-label-220"
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Home medication
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardHoldingRoomUX-FormGroup-221"
            inline={true}>
            <FormField
              id="CardHoldingRoomUX-FormField-224"
              inline={true}>
              <label
                className="subtitle-indent"
                style={{paddingLeft: "20px", minWidth: "220px", maxWidth: "220px"}}>
                Anti Coagulation Drug
              </label>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-225"
              inline={true}>
              <Checkbox
                checked={props.PreOperationSequence?.holdingRoomData?.data?.anti_coagulation_drug}
                id="CardHoldingRoomUX-Checkbox-232"
                label={props.PreOperationSequence?.holdingRoomData?.data?.anti_coagulation_drug ? "YES" : "NO"}
                name="anti_coagulation_drug"
                onChange={props.onChangeData}
                toggle={true}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardHoldingRoomUX-FormGroup-222"
            inline={true}>
            <FormField
              id="CardHoldingRoomUX-FormField-226"
              inline={true}>
              <label
                className="subtitle-indent"
                id="CardHoldingRoomUX-label-234"
                style={{paddingLeft: "20px", minWidth: "220px", maxWidth: "220px"}}>
                DM Drug
              </label>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-227"
              inline={true}>
              <Checkbox
                checked={props.PreOperationSequence?.holdingRoomData?.data?.dm_drug}
                id="CardHoldingRoomUX-Checkbox-231"
                label={props.PreOperationSequence?.holdingRoomData?.data?.dm_drug ? "YES" : "NO"}
                name="dm_drug"
                onChange={props.onChangeData}
                toggle={true}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardHoldingRoomUX-FormGroup-223"
            inline={true}>
            <FormField
              id="CardHoldingRoomUX-FormField-228"
              inline={true}>
              <label
                className="subtitle-indent"
                id="CardHoldingRoomUX-label-233"
                style={{paddingLeft: "20px", minWidth: "220px", maxWidth: "220px"}}>
                Anti Hypertensive Drug
              </label>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-229"
              inline={true}>
              <Checkbox
                checked={props.PreOperationSequence?.holdingRoomData?.data?.anti_hypertensive_drug}
                id="CardHoldingRoomUX-Checkbox-230"
                label={props.PreOperationSequence?.holdingRoomData?.data?.anti_hypertensive_drug ? "YES" : "NO"}
                name="anti_hypertensive_drug"
                onChange={props.onChangeData}
                toggle={true}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardHoldingRoomUX-FormGroup-236"
            inline={true}>
            <FormField
              className="required"
              id="CardHoldingRoomUX-FormField-264"
              inline={true}>
              <label
                id="CardHoldingRoomUX-label-266"
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Valuable
              </label>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-265"
              inline={true}>
              <Checkbox
                checked={props.PreOperationSequence?.holdingRoomData?.data?.valuable}
                id="CardHoldingRoomUX-Checkbox-276"
                label={props.PreOperationSequence?.holdingRoomData?.data?.valuable ? "YES" : "NO"}
                name="valuable"
                onChange={props.onChangeData}
                toggle={true}>
              </Checkbox>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-323"
              style={{display: props.PreOperationSequence?.holdingRoomData?.data?.valuable ? "" : "none"}}>
              <Dropdown
                clearable={true}
                id="CardHoldingRoomUX-Dropdown-324"
                multiple={true}
                name="valuable_detail"
                onChange={props.onChangeData}
                options={props.masterOptions?.orValuable || []}
                search={true}
                selection={true}
                value={props.PreOperationSequence?.holdingRoomData?.data?.valuable_detail || []}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardHoldingRoomUX-FormGroup-237"
            inline={true}>
            <FormField
              className="required"
              id="CardHoldingRoomUX-FormField-262"
              inline={true}>
              <label
                id="CardHoldingRoomUX-label-267"
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Implant
              </label>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-263"
              inline={true}>
              <Checkbox
                checked={props.PreOperationSequence?.holdingRoomData?.data?.implant}
                id="CardHoldingRoomUX-Checkbox-277"
                label={props.PreOperationSequence?.holdingRoomData?.data?.implant ? "YES" : "NO"}
                name="implant"
                onChange={props.onChangeData}
                toggle={true}>
              </Checkbox>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-325"
              style={{display: props.PreOperationSequence?.holdingRoomData?.data?.implant ? "" : "none" }}>
              <Input
                id="CardHoldingRoomUX-Input-326"
                name="implant_detail"
                onChange={props.onChangeData}
                value={props.PreOperationSequence?.holdingRoomData?.data?.implant_detail || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardHoldingRoomUX-FormGroup-238"
            inline={true}>
            <FormField
              className="required"
              id="CardHoldingRoomUX-FormField-260"
              inline={true}>
              <label
                id="CardHoldingRoomUX-label-268"
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Limitation
              </label>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-261"
              inline={true}
              width={10}>
              <Dropdown
                allowAdditions={true}
                fluid={true}
                id="CardHoldingRoomUX-Dropdown-278"
                name="limitation"
                onAddItem={props.onAddOption}
                onChange={props.onChangeData}
                options={props.limitationOptions|| []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.PreOperationSequence?.holdingRoomData?.data?.limitation}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardHoldingRoomUX-FormGroup-239"
            inline={true}>
            <FormField
              className="required"
              id="CardHoldingRoomUX-FormField-258"
              inline={true}>
              <label
                id="CardHoldingRoomUX-label-269"
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Status of Conscious
              </label>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-259"
              inline={true}
              width={10}>
              <Dropdown
                fluid={true}
                id="CardHoldingRoomUX-Dropdown-279"
                name="status_of_conscious"
                onChange={props.onChangeData}
                options={props.masterOptions?.statusOfConscious || []}
                selection={true}
                style={{width: "100%"}}
                value={props.PreOperationSequence?.holdingRoomData?.data?.status_of_conscious}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardHoldingRoomUX-FormGroup-240"
            inline={true}>
            <FormField
              className="required"
              id="CardHoldingRoomUX-FormField-256"
              inline={true}>
              <label
                id="CardHoldingRoomUX-label-270"
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Psychological Status
              </label>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-257"
              inline={true}
              width={10}>
              <Dropdown
                fluid={true}
                id="CardHoldingRoomUX-Dropdown-280"
                name="psychological_status"
                onChange={props.onChangeData}
                options={props.masterOptions?.psychologicalStatus || []}
                selection={true}
                style={{width: "100%"}}
                value={props.PreOperationSequence?.holdingRoomData?.data?.psychological_status}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardHoldingRoomUX-FormGroup-241"
            inline={true}>
            <FormField
              className="required"
              id="CardHoldingRoomUX-FormField-254"
              inline={true}>
              <label
                id="CardHoldingRoomUX-label-271"
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Respiratory Status
              </label>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-255"
              inline={true}
              width={3}>
              <Dropdown
                fluid={true}
                id="CardHoldingRoomUX-Dropdown-281"
                name="respiratory_status"
                onChange={props.onChangeData}
                options={props.masterOptions?.respiratoryStatus || []}
                selection={true}
                style={{width: "100%"}}
                value={props.PreOperationSequence?.holdingRoomData?.data?.respiratory_status}>
              </Dropdown>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-346"
              inline={true}
              width={7}>
              <Input
                id="CardHoldingRoomUX-Input-347"
                name="respiratory_status_detail"
                onChange={props.onChangeData}
                style={{width: "100%"}}
                value={props.PreOperationSequence?.holdingRoomData?.data?.respiratory_status_detail}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardHoldingRoomUX-FormGroup-286"
            inline={true}>
            <FormField
              id="CardHoldingRoomUX-FormField-287"
              inline={true}>
              <label
                id="CardHoldingRoomUX-label-289"
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Oxygen
              </label>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-348"
              inline={true}>
              <Radio
                checked={props.PreOperationSequence?.holdingRoomData?.data?.oxygen_type === "roomAir"}
                id="CardHoldingRoomUX-Radio-351"
                label="Room Air"
                name="oxygen_type"
                onChange={props.onChangeData}
                value="roomAir">
              </Radio>
              <Radio
                checked={props.PreOperationSequence?.holdingRoomData?.data?.oxygen_type === "typeOfOxygen"}
                id="CardHoldingRoomUX-Radio-352"
                label="Type of Oxygen"
                name="oxygen_type"
                onChange={props.onChangeData}
                value="typeOfOxygen">
              </Radio>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-349"
              inline={true}>
              <Dropdown
                id="CardHoldingRoomUX-Dropdown-353"
                name="oxygen_type_detail"
                onChange={props.onChangeData}
                options={props.masterOptions?.typeOfOxygen || []}
                selection={true}
                style={{ width: "100%", ...(props.PreOperationSequence?.holdingRoomData?.data?.oxygen_type !== "typeOfOxygen" && { display: "none" }) }}
                value={props.PreOperationSequence?.holdingRoomData?.data?.oxygen_type_detail}>
              </Dropdown>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-288"
              inline={true}
              style={{ display: "flex", alignItems: "center", ...(props.PreOperationSequence?.holdingRoomData?.data?.oxygen_type !== "typeOfOxygen" && { display: "none" }) }}>
              <Dropdown
                id="CardHoldingRoomUX-Dropdown-290"
                name="oxygen"
                onChange={props.onChangeData}
                options={[ { key: 1, value: 1, text: "1" }, { key: 2, value: 2, text: "2" }, { key: 3, value: 3, text: "3" }, { key: 4, value: 4, text: "4" }, { key: 5, value: 5, text: "5" }, { key: 6, value: 6, text: "6" }, { key: 7, value: 7, text: "7" }, { key: 8, value: 8, text: "8" }, { key: 9, value: 9, text: "9" }, { key: 10, value: 10, text: "10" } ]}
                selection={true}
                value={props.PreOperationSequence?.holdingRoomData?.data?.oxygen}>
              </Dropdown>
              <label
                id="CardHoldingRoomUX-label-291">
                L/min
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardHoldingRoomUX-FormGroup-242"
            inline={true}>
            <FormField
              id="CardHoldingRoomUX-FormField-252"
              inline={true}>
              <label
                id="CardHoldingRoomUX-label-272"
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Lab Data
              </label>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-253"
              inline={true}>
              <label
                id="CardHoldingRoomUX-label-327">
                {props.PreOperationSequence?.holdingRoomData?.data?.lab_data || ""}
              </label>
              <Button
                icon="list"
                id="CardHoldingRoomUX-Button-282"
                onClick={props.onShowLabData}
                size="mini"
                type="button">
              </Button>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardHoldingRoomUX-FormGroup-243"
            inline={true}>
            <FormField
              id="CardHoldingRoomUX-FormField-250"
              inline={true}>
              <label
                id="CardHoldingRoomUX-label-273"
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Lab Remark
              </label>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-251"
              inline={true}
              width={10}>
              <Input
                fluid={true}
                id="CardHoldingRoomUX-Input-283"
                name="lab_remark"
                onChange={props.onChangeData}
                style={{width: "100%"}}
                value={props.PreOperationSequence?.holdingRoomData?.data?.lab_remark || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardHoldingRoomUX-FormGroup-244"
            inline={true}>
            <FormField
              id="CardHoldingRoomUX-FormField-248"
              inline={true}>
              <label
                id="CardHoldingRoomUX-label-274"
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Blood
              </label>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-249"
              inline={true}
              width={10}>
              <Input
                fluid={true}
                id="CardHoldingRoomUX-Input-284"
                name="blood"
                onChange={props.onChangeData}
                style={{width: "100%"}}
                value={props.PreOperationSequence?.holdingRoomData?.data?.blood || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardHoldingRoomUX-FormGroup-245"
            inline={true}
            style={{alignItems: "baseline"}}>
            <FormField
              id="CardHoldingRoomUX-FormField-246"
              style={{alignItems: "baseline"}}>
              <label
                id="CardHoldingRoomUX-label-275"
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Remark
              </label>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-247"
              inline={true}
              width={10}>
              <TextArea
                id="CardHoldingRoomUX-TextArea-285"
                name="remark"
                onChange={props.onChangeData}
                rows={5}
                style={{width: "100%"}}
                value={props.PreOperationSequence?.holdingRoomData?.data?.remark || ""}>
              </TextArea>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardHoldingRoomUX-FormGroup-292"
            inline={true}
            style={{alignItems: "baseline"}}>
            <FormField
              id="CardHoldingRoomUX-FormField-294"
              style={{alignItems: "baseline"}}>
              <label
                id="CardHoldingRoomUX-label-298"
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Medication
              </label>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-296"
              width={16}>
              <div
                id="CardHoldingRoomUX-div-300"
                style={{width: "100%"}}>
                {props.divMedication}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardHoldingRoomUX-FormGroup-293"
            inline={true}
            style={{alignItems: "baseline"}}>
            <FormField
              id="CardHoldingRoomUX-FormField-295"
              inline={true}
              style={{alignItems: "baseline"}}>
              <label
                id="CardHoldingRoomUX-label-299"
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Other
              </label>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-297"
              width={16}>
              <div
                id="CardHoldingRoomUX-div-301"
                style={{width: "100%"}}>
                {props.divOtherMedication}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardHoldingRoomUX-FormGroup-302"
            style={{display: "none", backgroundColor: "rgb(253, 248, 221)", padding: "30px 10px", marginTop: "2rem"}}>
            <FormField
              id="CardHoldingRoomUX-FormField-303"
              width={16}>
              <FormGroup
                id="CardHoldingRoomUX-FormGroup-304"
                inline={true}>
                <FormField
                  id="CardHoldingRoomUX-FormField-306"
                  inline={true}
                  width={7}>
                  <label
                    id="CardHoldingRoomUX-label-311"
                    style={{width: "100%", maxWidth: "max-content"}}>
                    Nursing Diagnosis
                  </label>
                  <Dropdown
                    allowAdditions={true}
                    clearable={true}
                    fluid={true}
                    id="CardHoldingRoomUX-Dropdown-339"
                    name="nursing_diagnosis"
                    onAddItem={props.onAddOption}
                    onChange={props.onChangeData}
                    options={props.orNDOptions || []}
                    search={true}
                    selection={true}
                    style={{width: "100%"}}
                    value={props.PreOperationSequence?.holdingRoomData?.data?.nursing_diagnosis}>
                  </Dropdown>
                </FormField>
                <FormField
                  id="CardHoldingRoomUX-FormField-307"
                  inline={true}
                  width={9}>
                  <label
                    id="CardHoldingRoomUX-label-312">
                    Goal
                  </label>
                  <Dropdown
                    allowAdditions={true}
                    clearable={true}
                    fluid={true}
                    id="CardHoldingRoomUX-Dropdown-338"
                    multiple={true}
                    name="goal"
                    onAddItem={props.onAddOption}
                    onChange={props.onChangeData}
                    options={props.orGoal || []}
                    search={true}
                    selection={true}
                    style={{width: "100%"}}
                    value={props.PreOperationSequence?.holdingRoomData?.data?.goal}>
                  </Dropdown>
                </FormField>
              </FormGroup>
              <FormGroup
                id="CardHoldingRoomUX-FormGroup-305"
                inline={true}>
                <FormField
                  id="CardHoldingRoomUX-FormField-308"
                  inline={true}
                  width={7}>
                  <label
                    id="CardHoldingRoomUX-label-313"
                    style={{width: "100%", maxWidth: "max-content"}}>
                    Plan and implementation
                  </label>
                  <Dropdown
                    allowAdditions={true}
                    clearable={true}
                    fluid={true}
                    id="CardHoldingRoomUX-Dropdown-320"
                    multiple={true}
                    name="plan_and_implementation"
                    onAddItem={props.onAddOption}
                    onChange={props.onChangeData}
                    options={props.planAndImpleHrOptions || []}
                    search={true}
                    selection={true}
                    style={{width: "100%"}}
                    value={props.PreOperationSequence?.holdingRoomData?.data?.plan_and_implementation || []}>
                  </Dropdown>
                </FormField>
                <FormField
                  id="CardHoldingRoomUX-FormField-309"
                  inline={true}
                  width={9}>
                  <label
                    id="CardHoldingRoomUX-label-314">
                    Evaluation
                  </label>
                  <Dropdown
                    allowAdditions={true}
                    clearable={true}
                    fluid={true}
                    id="CardHoldingRoomUX-Dropdown-337"
                    multiple={true}
                    name="evaluation"
                    onAddItem={props.onAddOption}
                    onChange={props.onChangeData}
                    options={props.orEvaluation || []}
                    search={true}
                    selection={true}
                    style={{width: "100%"}}
                    value={props.PreOperationSequence?.holdingRoomData?.data?.evaluation}>
                  </Dropdown>
                </FormField>
              </FormGroup>
            </FormField>
          </FormGroup>
          <div
            id="CardHoldingRoomUX-div-345"
            style={{width: "100%"}}>
            {props.divNursingDiagnosisItem}
          </div>
          <FormGroup
            id="CardHoldingRoomUX-FormGroup-328"
            inline={true}
            style={{marginTop: "2rem"}}>
            <FormField
              id="CardHoldingRoomUX-FormField-329"
              inline={true}
              style={{flex:1}}>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-330">
              <div
                id="CardHoldingRoomUX-div-333">
                {props.buttonSave}
              </div>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-331">
              <div
                id="CardHoldingRoomUX-div-334">
                {props.buttonConfirm}
              </div>
            </FormField>
            <FormField
              id="CardHoldingRoomUX-FormField-332">
              <div
                id="CardHoldingRoomUX-div-335">
                {props.buttonUnconfirm}
              </div>
            </FormField>
          </FormGroup>
        </Form>
      </div>
    )
}


export default CardHoldingRoomUX

export const screenPropsDefault = {}

/* Date Time : Mon Feb 24 2025 13:14:45 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoom"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\",  padding: \"20px\",  backgroundColor: \"rgba(198, 235, 243, 0.2)\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-div-1"
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"5px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Holding Room"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-2"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"18px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.status || \"\""
        },
        "color": {
          "type": "code",
          "value": "props.statusColor"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Label-3"
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "hr",
      "parent": 1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-hr-4"
        }
      },
      "seq": 4,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 175,
      "name": "Form",
      "parent": 0,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Form-175"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 176,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormGroup-176"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 177,
      "name": "FormField",
      "parent": 176,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-177"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "FormField",
      "parent": 176,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-178"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 179,
      "name": "FormField",
      "parent": 176,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-179"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 180,
      "name": "FormField",
      "parent": 176,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-180"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": null,
      "id": 181,
      "name": "label",
      "parent": 177,
      "props": {
        "children": {
          "type": "value",
          "value": "Patient identification"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-181"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 182,
      "name": "Checkbox",
      "parent": 178,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.verbally"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Checkbox-182"
        },
        "label": {
          "type": "value",
          "value": "Verbally"
        },
        "name": {
          "type": "value",
          "value": "verbally"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 183,
      "name": "Checkbox",
      "parent": 179,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.name_band"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Checkbox-183"
        },
        "label": {
          "type": "value",
          "value": "Name band"
        },
        "name": {
          "type": "value",
          "value": "name_band"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 184,
      "name": "Checkbox",
      "parent": 180,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.chart"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Checkbox-184"
        },
        "label": {
          "type": "value",
          "value": "Chart"
        },
        "name": {
          "type": "value",
          "value": "chart"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 185,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormGroup-185"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 186,
      "name": "FormField",
      "parent": 185,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-186"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 187,
      "name": "FormField",
      "parent": 185,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-187"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": null,
      "id": 188,
      "name": "label",
      "parent": 186,
      "props": {
        "children": {
          "type": "value",
          "value": "History illness"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-188"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 189,
      "name": "Input",
      "parent": 187,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Input-189"
        },
        "name": {
          "type": "value",
          "value": "history_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.history_illness || \"\""
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 190,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormGroup-190"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"flex-start\"}"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 191,
      "name": "FormField",
      "parent": 190,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-191"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 192,
      "name": "FormField",
      "parent": 190,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-192"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": null,
      "id": 193,
      "name": "label",
      "parent": 191,
      "props": {
        "children": {
          "type": "value",
          "value": "Previous Operation"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-193"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": null,
      "id": 194,
      "name": "label",
      "parent": 192,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.previous_operation || \"-\""
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-194"
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 195,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "id": {
          "type": "value",
          "value": "title-procedure-confirm"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 195,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 196,
      "name": "FormField",
      "parent": 195,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-196"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 197,
      "name": "FormField",
      "parent": 195,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-197"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"-7rem\"}"
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": null,
      "id": 198,
      "name": "label",
      "parent": 196,
      "props": {
        "children": {
          "type": "value",
          "value": "Procedure Confirm"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-198"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 199,
      "name": "Checkbox",
      "parent": 197,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.procedure_confirm"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Checkbox-199"
        },
        "label": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.procedure_confirm ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "procedure_confirm"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 200,
      "name": "FormField",
      "parent": 195,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-200"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 201,
      "name": "Input",
      "parent": 200,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.PreOperationSequence?.holdingRoomData?.data?.procedure_confirm"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Input-201"
        },
        "name": {
          "type": "value",
          "value": "procedure_confirm_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.procedure_confirm_detail || \"\""
        }
      },
      "seq": 201,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 202,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormGroup-202"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 203,
      "name": "FormField",
      "parent": 202,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-203"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 204,
      "name": "FormField",
      "parent": 202,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-204"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": null,
      "id": 205,
      "name": "label",
      "parent": 203,
      "props": {
        "children": {
          "type": "value",
          "value": "Mark site Confirm"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-205"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 205,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 206,
      "name": "Radio",
      "parent": 204,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm === \"yes\""
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Radio-206"
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "mark_site_confirm"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "yes"
        }
      },
      "seq": 206,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 207,
      "name": "FormField",
      "parent": 202,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-207"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 207,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 208,
      "name": "FormField",
      "parent": 202,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-208"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 209,
      "name": "Radio",
      "parent": 207,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm === \"no\""
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Radio-209"
        },
        "label": {
          "type": "value",
          "value": "No"
        },
        "name": {
          "type": "value",
          "value": "mark_site_confirm"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "no"
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 210,
      "name": "Radio",
      "parent": 208,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm === \"n/a\""
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Radio-210"
        },
        "label": {
          "type": "value",
          "value": "N/A"
        },
        "name": {
          "type": "value",
          "value": "mark_site_confirm"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "n/a"
        }
      },
      "seq": 210,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 211,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormGroup-211"
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 212,
      "name": "FormField",
      "parent": 211,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-212"
        }
      },
      "seq": 212,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 213,
      "name": "FormField",
      "parent": 211,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-213"
        }
      },
      "seq": 213,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 214,
      "name": "FormField",
      "parent": 211,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-214"
        }
      },
      "seq": 214,
      "void": false
    },
    {
      "from": null,
      "id": 215,
      "name": "label",
      "parent": 212,
      "props": {
        "children": {
          "type": "value",
          "value": "Confirmation Surgical site per PT/Family"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-215"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 215,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 216,
      "name": "Radio",
      "parent": 213,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.confirm_surgical === \"yes\""
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Radio-216"
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "confirm_surgical"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "yes"
        }
      },
      "seq": 216,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 217,
      "name": "Radio",
      "parent": 214,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.confirm_surgical === \"no\""
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Radio-217"
        },
        "label": {
          "type": "value",
          "value": "No"
        },
        "name": {
          "type": "value",
          "value": "confirm_surgical"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "no"
        }
      },
      "seq": 217,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 218,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormGroup-218"
        }
      },
      "seq": 218,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 219,
      "name": "FormField",
      "parent": 218,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-219"
        }
      },
      "seq": 219,
      "void": false
    },
    {
      "from": null,
      "id": 220,
      "name": "label",
      "parent": 219,
      "props": {
        "children": {
          "type": "value",
          "value": "Home medication"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-220"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 220,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 221,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormGroup-221"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 221,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 222,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormGroup-222"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 222,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 223,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormGroup-223"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 223,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 224,
      "name": "FormField",
      "parent": 221,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-224"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 224,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 225,
      "name": "FormField",
      "parent": 221,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-225"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 225,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 226,
      "name": "FormField",
      "parent": 222,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-226"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 226,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 227,
      "name": "FormField",
      "parent": 222,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-227"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 227,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 228,
      "name": "FormField",
      "parent": 223,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-228"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 228,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 229,
      "name": "FormField",
      "parent": 223,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-229"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 229,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 230,
      "name": "Checkbox",
      "parent": 229,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.anti_hypertensive_drug"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Checkbox-230"
        },
        "label": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.anti_hypertensive_drug ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "anti_hypertensive_drug"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 230,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 231,
      "name": "Checkbox",
      "parent": 227,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.dm_drug"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Checkbox-231"
        },
        "label": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.dm_drug ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "dm_drug"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 231,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 232,
      "name": "Checkbox",
      "parent": 225,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.anti_coagulation_drug"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Checkbox-232"
        },
        "label": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.anti_coagulation_drug ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "anti_coagulation_drug"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 232,
      "void": false
    },
    {
      "from": null,
      "id": 233,
      "name": "label",
      "parent": 228,
      "props": {
        "children": {
          "type": "value",
          "value": "Anti Hypertensive Drug"
        },
        "className": {
          "type": "value",
          "value": "subtitle-indent"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-233"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"20px\", minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 233,
      "void": false
    },
    {
      "from": null,
      "id": 234,
      "name": "label",
      "parent": 226,
      "props": {
        "children": {
          "type": "value",
          "value": "DM Drug"
        },
        "className": {
          "type": "value",
          "value": "subtitle-indent"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-234"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"20px\", minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 234,
      "void": false
    },
    {
      "from": null,
      "id": 235,
      "name": "label",
      "parent": 224,
      "props": {
        "children": {
          "type": "value",
          "value": "Anti Coagulation Drug"
        },
        "className": {
          "type": "value",
          "value": "subtitle-indent"
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"20px\", minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 235,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 236,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormGroup-236"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 236,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 237,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormGroup-237"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 237,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 238,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormGroup-238"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 238,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 239,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormGroup-239"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 239,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 240,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormGroup-240"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 240,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 241,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormGroup-241"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 241,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 242,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormGroup-242"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 243,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 243,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormGroup-243"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 244,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 244,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormGroup-244"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 245,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 245,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormGroup-245"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"baseline\"}"
        }
      },
      "seq": 286,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 246,
      "name": "FormField",
      "parent": 245,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-246"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"baseline\"}"
        }
      },
      "seq": 246,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 247,
      "name": "FormField",
      "parent": 245,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-247"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 247,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 248,
      "name": "FormField",
      "parent": 244,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-248"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 248,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 249,
      "name": "FormField",
      "parent": 244,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-249"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 249,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 250,
      "name": "FormField",
      "parent": 243,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-250"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 250,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 251,
      "name": "FormField",
      "parent": 243,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-251"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 251,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 252,
      "name": "FormField",
      "parent": 242,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-252"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 252,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 253,
      "name": "FormField",
      "parent": 242,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-253"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 253,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 254,
      "name": "FormField",
      "parent": 241,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-254"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 254,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 255,
      "name": "FormField",
      "parent": 241,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-255"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 255,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 256,
      "name": "FormField",
      "parent": 240,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-256"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 256,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 257,
      "name": "FormField",
      "parent": 240,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-257"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 257,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 258,
      "name": "FormField",
      "parent": 239,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-258"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 258,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 259,
      "name": "FormField",
      "parent": 239,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-259"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 259,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 260,
      "name": "FormField",
      "parent": 238,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-260"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 260,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 261,
      "name": "FormField",
      "parent": 238,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-261"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 261,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 262,
      "name": "FormField",
      "parent": 237,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-262"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 262,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 263,
      "name": "FormField",
      "parent": 237,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-263"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 263,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 264,
      "name": "FormField",
      "parent": 236,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-264"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 264,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 265,
      "name": "FormField",
      "parent": 236,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-265"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 265,
      "void": false
    },
    {
      "from": null,
      "id": 266,
      "name": "label",
      "parent": 264,
      "props": {
        "children": {
          "type": "value",
          "value": "Valuable"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-266"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 266,
      "void": false
    },
    {
      "from": null,
      "id": 267,
      "name": "label",
      "parent": 262,
      "props": {
        "children": {
          "type": "value",
          "value": "Implant"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-267"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 267,
      "void": false
    },
    {
      "from": null,
      "id": 268,
      "name": "label",
      "parent": 260,
      "props": {
        "children": {
          "type": "value",
          "value": "Limitation"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-268"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 268,
      "void": false
    },
    {
      "from": null,
      "id": 269,
      "name": "label",
      "parent": 258,
      "props": {
        "children": {
          "type": "value",
          "value": "Status of Conscious"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-269"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 269,
      "void": false
    },
    {
      "from": null,
      "id": 270,
      "name": "label",
      "parent": 256,
      "props": {
        "children": {
          "type": "value",
          "value": "Psychological Status"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-270"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 270,
      "void": false
    },
    {
      "from": null,
      "id": 271,
      "name": "label",
      "parent": 254,
      "props": {
        "children": {
          "type": "value",
          "value": "Respiratory Status"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-271"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 271,
      "void": false
    },
    {
      "from": null,
      "id": 272,
      "name": "label",
      "parent": 252,
      "props": {
        "children": {
          "type": "value",
          "value": "Lab Data"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-272"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 272,
      "void": false
    },
    {
      "from": null,
      "id": 273,
      "name": "label",
      "parent": 250,
      "props": {
        "children": {
          "type": "value",
          "value": "Lab Remark"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-273"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 273,
      "void": false
    },
    {
      "from": null,
      "id": 274,
      "name": "label",
      "parent": 248,
      "props": {
        "children": {
          "type": "value",
          "value": "Blood"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-274"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 274,
      "void": false
    },
    {
      "from": null,
      "id": 275,
      "name": "label",
      "parent": 246,
      "props": {
        "children": {
          "type": "value",
          "value": "Remark"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-275"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 275,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 276,
      "name": "Checkbox",
      "parent": 265,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.valuable"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Checkbox-276"
        },
        "label": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.valuable ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "valuable"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 276,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 277,
      "name": "Checkbox",
      "parent": 263,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.implant"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Checkbox-277"
        },
        "label": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.implant ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "implant"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 277,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 278,
      "name": "Dropdown",
      "parent": 261,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Dropdown-278"
        },
        "name": {
          "type": "value",
          "value": "limitation"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOption"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.limitationOptions|| []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.limitation"
        }
      },
      "seq": 278,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 279,
      "name": "Dropdown",
      "parent": 259,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Dropdown-279"
        },
        "name": {
          "type": "value",
          "value": "status_of_conscious"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.statusOfConscious || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.status_of_conscious"
        }
      },
      "seq": 279,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 280,
      "name": "Dropdown",
      "parent": 257,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Dropdown-280"
        },
        "name": {
          "type": "value",
          "value": "psychological_status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.psychologicalStatus || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.psychological_status"
        }
      },
      "seq": 280,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 281,
      "name": "Dropdown",
      "parent": 255,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Dropdown-281"
        },
        "name": {
          "type": "value",
          "value": "respiratory_status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.respiratoryStatus || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.respiratory_status"
        }
      },
      "seq": 281,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 282,
      "name": "Button",
      "parent": 253,
      "props": {
        "icon": {
          "type": "value",
          "value": "list"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Button-282"
        },
        "onClick": {
          "type": "code",
          "value": "props.onShowLabData"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "type": {
          "type": "value",
          "value": "button"
        }
      },
      "seq": 327,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 283,
      "name": "Input",
      "parent": 251,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Input-283"
        },
        "name": {
          "type": "value",
          "value": "lab_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.lab_remark || \"\""
        }
      },
      "seq": 283,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 284,
      "name": "Input",
      "parent": 249,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Input-284"
        },
        "name": {
          "type": "value",
          "value": "blood"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.blood || \"\""
        }
      },
      "seq": 284,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 285,
      "name": "TextArea",
      "parent": 247,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-TextArea-285"
        },
        "name": {
          "type": "value",
          "value": "remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "rows": {
          "type": "code",
          "value": "5"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.remark || \"\""
        }
      },
      "seq": 285,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 286,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormGroup-286"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 242,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 287,
      "name": "FormField",
      "parent": 286,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-287"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 287,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 288,
      "name": "FormField",
      "parent": 286,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-288"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", ...(props.PreOperationSequence?.holdingRoomData?.data?.oxygen_type !== \"typeOfOxygen\" && { display: \"none\" }) }"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 349,
      "void": false
    },
    {
      "from": null,
      "id": 289,
      "name": "label",
      "parent": 287,
      "props": {
        "children": {
          "type": "value",
          "value": "Oxygen"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-289"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 289,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 290,
      "name": "Dropdown",
      "parent": 288,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Dropdown-290"
        },
        "name": {
          "type": "value",
          "value": "oxygen"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "[ { key: 1, value: 1, text: \"1\" }, { key: 2, value: 2, text: \"2\" }, { key: 3, value: 3, text: \"3\" }, { key: 4, value: 4, text: \"4\" }, { key: 5, value: 5, text: \"5\" }, { key: 6, value: 6, text: \"6\" }, { key: 7, value: 7, text: \"7\" }, { key: 8, value: 8, text: \"8\" }, { key: 9, value: 9, text: \"9\" }, { key: 10, value: 10, text: \"10\" } ]"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.oxygen"
        }
      },
      "seq": 290,
      "void": false
    },
    {
      "from": null,
      "id": 291,
      "name": "label",
      "parent": 288,
      "props": {
        "children": {
          "type": "value",
          "value": "L/min"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-291"
        }
      },
      "seq": 291,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 292,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormGroup-292"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"baseline\"}"
        }
      },
      "seq": 292,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 293,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormGroup-293"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"baseline\"}"
        }
      },
      "seq": 293,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 294,
      "name": "FormField",
      "parent": 292,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-294"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"baseline\"}"
        }
      },
      "seq": 294,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 295,
      "name": "FormField",
      "parent": 293,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-295"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"baseline\"}"
        }
      },
      "seq": 295,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 296,
      "name": "FormField",
      "parent": 292,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-296"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 296,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 297,
      "name": "FormField",
      "parent": 293,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-297"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 297,
      "void": false
    },
    {
      "from": null,
      "id": 298,
      "name": "label",
      "parent": 294,
      "props": {
        "children": {
          "type": "value",
          "value": "Medication"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-298"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 298,
      "void": false
    },
    {
      "from": null,
      "id": 299,
      "name": "label",
      "parent": 295,
      "props": {
        "children": {
          "type": "value",
          "value": "Other"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-299"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 299,
      "void": false
    },
    {
      "from": null,
      "id": 300,
      "name": "div",
      "parent": 296,
      "props": {
        "children": {
          "type": "code",
          "value": "props.divMedication",
          "valueEN": ""
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-div-300"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 300,
      "void": false
    },
    {
      "from": null,
      "id": 301,
      "name": "div",
      "parent": 297,
      "props": {
        "children": {
          "type": "code",
          "value": "props.divOtherMedication"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-div-301"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 301,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 302,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormGroup-302"
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\", backgroundColor: \"rgb(253, 248, 221)\", padding: \"30px 10px\", marginTop: \"2rem\"}"
        }
      },
      "seq": 302,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 303,
      "name": "FormField",
      "parent": 302,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-303"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 303,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 304,
      "name": "FormGroup",
      "parent": 303,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormGroup-304"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 304,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 305,
      "name": "FormGroup",
      "parent": 303,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormGroup-305"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 305,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 306,
      "name": "FormField",
      "parent": 304,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-306"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 306,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 307,
      "name": "FormField",
      "parent": 304,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-307"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 307,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 308,
      "name": "FormField",
      "parent": 305,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-308"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 308,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 309,
      "name": "FormField",
      "parent": 305,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-309"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 309,
      "void": false
    },
    {
      "from": null,
      "id": 311,
      "name": "label",
      "parent": 306,
      "props": {
        "children": {
          "type": "value",
          "value": "Nursing Diagnosis"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-311"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 311,
      "void": false
    },
    {
      "from": null,
      "id": 312,
      "name": "label",
      "parent": 307,
      "props": {
        "children": {
          "type": "value",
          "value": "Goal"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-312"
        }
      },
      "seq": 312,
      "void": false
    },
    {
      "from": null,
      "id": 313,
      "name": "label",
      "parent": 308,
      "props": {
        "children": {
          "type": "value",
          "value": "Plan and implementation"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-313"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 313,
      "void": false
    },
    {
      "from": null,
      "id": 314,
      "name": "label",
      "parent": 309,
      "props": {
        "children": {
          "type": "value",
          "value": "Evaluation"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-314"
        }
      },
      "seq": 314,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 320,
      "name": "Dropdown",
      "parent": 308,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Dropdown-320"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "plan_and_implementation"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOption"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.planAndImpleHrOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.plan_and_implementation || []"
        }
      },
      "seq": 320,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 321,
      "name": "FormField",
      "parent": 202,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-321"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 321,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 322,
      "name": "Input",
      "parent": 321,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Input-322"
        },
        "name": {
          "type": "value",
          "value": "mark_site_confirm_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{display: props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm === \"n/a\" ? \"\" : \"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm_detail || \"\""
        }
      },
      "seq": 322,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 323,
      "name": "FormField",
      "parent": 236,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-323"
        },
        "style": {
          "type": "code",
          "value": "{display: props.PreOperationSequence?.holdingRoomData?.data?.valuable ? \"\" : \"none\"}"
        }
      },
      "seq": 323,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 324,
      "name": "Dropdown",
      "parent": 323,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Dropdown-324"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "valuable_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.orValuable || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.valuable_detail || []"
        }
      },
      "seq": 324,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 325,
      "name": "FormField",
      "parent": 237,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-325"
        },
        "style": {
          "type": "code",
          "value": "{display: props.PreOperationSequence?.holdingRoomData?.data?.implant ? \"\" : \"none\" }"
        }
      },
      "seq": 325,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 326,
      "name": "Input",
      "parent": 325,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Input-326"
        },
        "name": {
          "type": "value",
          "value": "implant_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.implant_detail || \"\""
        }
      },
      "seq": 326,
      "void": false
    },
    {
      "from": null,
      "id": 327,
      "name": "label",
      "parent": 253,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.lab_data || \"\""
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-label-327"
        }
      },
      "seq": 282,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 328,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormGroup-328"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"2rem\"}"
        }
      },
      "seq": 345,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 329,
      "name": "FormField",
      "parent": 328,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-329"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 329,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 330,
      "name": "FormField",
      "parent": 328,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-330"
        }
      },
      "seq": 330,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 331,
      "name": "FormField",
      "parent": 328,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-331"
        }
      },
      "seq": 331,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 332,
      "name": "FormField",
      "parent": 328,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-332"
        }
      },
      "seq": 332,
      "void": false
    },
    {
      "from": null,
      "id": 333,
      "name": "div",
      "parent": 330,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-div-333"
        }
      },
      "seq": 333,
      "void": false
    },
    {
      "from": null,
      "id": 334,
      "name": "div",
      "parent": 331,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirm"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-div-334"
        }
      },
      "seq": 334,
      "void": false
    },
    {
      "from": null,
      "id": 335,
      "name": "div",
      "parent": 332,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonUnconfirm"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-div-335"
        }
      },
      "seq": 335,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 336,
      "name": "TextArea",
      "parent": 192,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-TextArea-336"
        },
        "name": {
          "type": "value",
          "value": "previous_operation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.previous_operation || \"\""
        }
      },
      "seq": 336,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 337,
      "name": "Dropdown",
      "parent": 309,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Dropdown-337"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "evaluation"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOption"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.orEvaluation || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.evaluation"
        }
      },
      "seq": 317,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 338,
      "name": "Dropdown",
      "parent": 307,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Dropdown-338"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "goal"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOption"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.orGoal || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.goal"
        }
      },
      "seq": 315,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 339,
      "name": "Dropdown",
      "parent": 306,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Dropdown-339"
        },
        "name": {
          "type": "value",
          "value": "nursing_diagnosis"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOption"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.orNDOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.nursing_diagnosis"
        }
      },
      "seq": 316,
      "void": false
    },
    {
      "from": null,
      "id": 345,
      "name": "div",
      "parent": 175,
      "props": {
        "children": {
          "type": "code",
          "value": "props.divNursingDiagnosisItem"
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-div-345"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 342,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 346,
      "name": "FormField",
      "parent": 241,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-346"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 346,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 347,
      "name": "Input",
      "parent": 346,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Input-347"
        },
        "name": {
          "type": "value",
          "value": "respiratory_status_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.respiratory_status_detail"
        }
      },
      "seq": 347,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 348,
      "name": "FormField",
      "parent": 286,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-348"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 288,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 349,
      "name": "FormField",
      "parent": 286,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-FormField-349"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 348,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 351,
      "name": "Radio",
      "parent": 348,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.oxygen_type === \"roomAir\""
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Radio-351"
        },
        "label": {
          "type": "value",
          "value": "Room Air"
        },
        "name": {
          "type": "value",
          "value": "oxygen_type"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "roomAir"
        }
      },
      "seq": 351,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 352,
      "name": "Radio",
      "parent": 348,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.oxygen_type === \"typeOfOxygen\""
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Radio-352"
        },
        "label": {
          "type": "value",
          "value": "Type of Oxygen"
        },
        "name": {
          "type": "value",
          "value": "oxygen_type"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "typeOfOxygen"
        }
      },
      "seq": 352,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 353,
      "name": "Dropdown",
      "parent": 349,
      "props": {
        "fluid": {
          "type": "code",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardHoldingRoomUX-Dropdown-353"
        },
        "name": {
          "type": "value",
          "value": "oxygen_type_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.typeOfOxygen || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", ...(props.PreOperationSequence?.holdingRoomData?.data?.oxygen_type !== \"typeOfOxygen\" && { display: \"none\" }) }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.oxygen_type_detail"
        }
      },
      "seq": 353,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": false,
  "memo": false,
  "name": "CardHoldingRoomUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
